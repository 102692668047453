<template>
  <el-card>
    <el-form
      ref="form"
      v-loading="loading"
      :model="form"
      class="assest-save-form mr10"
      label-width="100px"
    >
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item
            label="模板名称"
            prop="templateName"
            :rules="[ { required: true, message: '模板名称不能为空', trigger: 'change' }]"
          >
            <el-input
              v-model="form.templateName"
              placeholder="请输入模板名称"
            />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item
            label="模板编号"
            prop="code"
            :rules="[ { required: true, message: '模板编号不能为空', trigger: 'change' }]"
          >
            <el-input
              v-model="form.code"
              :disabled="query.id && query.t!=='2'"
              placeholder="请输入模板编号"
            />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item
            label="文档目录"
          >
            <el-cascader
              v-model="form.directoryId"
              :options="folders"
              popper-class="cas-class"
              :show-all-levels="false"
              style="width: 100%;"
              :props="{ emitPath: false, expandTrigger: 'hover', value: 'id', label: 'directoryName',checkStrictly: true,showAllLevels:false }"
            />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item
            label="标签"
            prop="labelIdList"
          >
            <el-select
              v-model="form.labelIdList"
              multiple
              placeholder="请选择"
            >
              <el-option
                v-for="item in labels"
                :key="item.id"
                :label="item.labelName"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="模板说明">
        <el-input
          v-model="form.description"
          placeholder="请输入模板说明"
          type="textarea"
        />
      </el-form-item>
      <EditorVue
        v-if="editorInit"
        style="border: 1px solid rgb(204, 204, 204);"
        :content.sync="contentInfo"
      />
    </el-form>
    <div class="text-center mt20">
      <el-button size="small" @click="back">
        取 消
      </el-button>
      <el-button
        type="primary"
        size="small"
        :disabled="loading"
        @click="submitForm"
      >
        保 存
      </el-button>
      <el-button
        size="small"
        type="primary"
        @click="preview"
      >
        预 览
      </el-button>
    </div>
  </el-card>
</template>

<script>
import { success, error } from '@core/utils/utils'
import { GetLabelList, GetDirectoryOptions } from '@/api/regime/regime'
import EditorVue from '@core/components/editor-vue/EditorVue.vue'
import { SaveOrUpdateTemplate } from '@/api/knowledgeBase/template'
import { GetTemplateById } from '@/api/knowledgeBase/template'

export default {
  components: {
    EditorVue,
  },
  data() {
    return {
      loading: false,
      editorInit: true,
      form: {
        code: '',
        templateName: '',
        description: '',
        contentList: [],
        labelIdList: [],
        templateType: 3,
        directoryId: 0,
      },
      templateId: '',
      linkFileName: '',
      labels: [],
      folders: [],
      formworks: [],
      query: this.$route.query,
      contentInfo: {
        directoryName: '',
        content: '',
      },
    }
  },
  mounted() {
    this.navActiveInit(1, '制度流程', '模板管理')
    this.getDirectoryOptions()
    this.getLabelList()
    if (this.query.id) {
      this.getRegimeById()
    }
  },
  destroyed() {
    this.navActiveInit(2, '制度流程', '模板管理', '/regime')
  },
  methods: {
    back() {
      this.$router.push({
        path: '/regime',
      })
    },
    getDirectoryOptions() {
      GetDirectoryOptions().then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.folders = this.listToTree(resData.data)
        }
      })
    },
    getLabelList() {
      GetLabelList().then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.labels = resData.data
        }
      })
    },
    getRegimeById() {
      GetTemplateById({ code: this.query.id }).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          const dd = resData.data
          this.form.code = dd.code
          this.form.templateName = dd.templateName
          this.form.description = dd.description
          this.form.directoryId = dd.directoryId
          if (dd.contentList.length > 0) {
            // eslint-disable-next-line prefer-destructuring
            this.contentInfo = dd.contentList[0]
          }
          if (dd.labelIdList) {
            this.form.labelIdList = dd.labelIdList.filter(num => num !== 0)
          }
          if (this.query.t === '2') {
            this.form.templateName += ' - 副本'
            this.form.code += ' - 副本'
          }
        }
      })
    },
    listToTree(list) {
      const map = {}
      // eslint-disable-next-line no-restricted-syntax
      for (const item of list) {
        item.label = item.name
        map[item.id] = item
      }
      list.forEach(item => {
        map[item.id] = item
        if (item.parentId > 0) {
          const parent = map[item.parentId]
          if (parent) {
            if (parent.children === undefined) {
              parent.children = []
            }
            parent.children.push(item)
          }
        }
      })
      return list.filter(i => i.parentId === 0)
    },
    submitForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          this.form.contentList[0] = {
            content: this.contentInfo.content,
            directoryName: '',
            filterOption: '',
          }
          this.form.operateType = this.query.t === '3' ? 2 : 1
          SaveOrUpdateTemplate(this.form).then(res => {
            this.loading = false
            const resData = res.data
            if (resData.code === 0) {
              success(resData.msg)
              this.back()
            } else {
              error(resData.msg)
            }
          })
        }
      })
    },
    preview() {
      // 没保存之前保存在浏览器缓存
      if (this.contentInfo.content) {
        localStorage.setItem('htmlStr', this.contentInfo.content)
        const routeUrl = this.$router.resolve({
          path: '/template-preview',
          query: { id: this.id },
        })
        window.open(routeUrl.href, '_blank')
      } else {
        error('内容为空，无法预览')
      }
    },
  },
}
</script>

<style>
.assest-save-form {
  padding-right: 10px;
}
.assest-save-form .el-icon-question {
  position: absolute;
  top: 14px;
  right: -20px;
}
.cas-class .el-radio__inner{
  display: none;
}
</style>
