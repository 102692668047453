import { downloadByGet, get, post } from '@/libs/axios'

export const GetRegimeListByPage = params => post('/template/regime/GetRegimeTemplateListByPage', params)
export const DeleteRegimeByIds = params => post('/regime/DeleteRegimeByIds', params)
export const DownloadFile = id => downloadByGet('/regime/DownloadRegimeFile', { id })
export const saveOrUpdateRegime = (params, form) => {
  const submitUrl = form.id ? '/regime/UpdateRegime' : '/regime/CreateRegime'
  return post(submitUrl, params)
}
export const saveOrUpdateRegimeByTemplate = form => {
  const submitUrl = form.id ? '/regime/UpdateRegimeByTemplate' : '/regime/CreateRegimeByTemplate'
  return post(submitUrl, form)
}
export const saveOrUpdateLibrary = form => {
  const submitUrl = form.id ? '/template/directory/UpdateDirectory' : '/template/directory/CreateTemplateDirectory'
  return post(submitUrl, form)
}
export const GetRegimeById = params => post('/regime/GetRegimeById', params)
export const CreateLabel = params => post('/template/label/CreateLabel', params)
export const DeleteLabelByIds = params => post('/template/label/DeleteLabelByIds', params)
export const GetLabelList = () => get('/template/label/GetLabelList')
export const GetAllLibrary = () => get('/template/directory/GetDirectoryTree')
export const GetTemplateOptions = () => get('/regime/template/GetTemplateOptions')
export const GetDirectoryOptions = () => get('/template/directory/GetDirectoryOptions')
export const GetLibraryOptions = () => get('/regime/library/GetLibraryOptions')
export const DeleteLibraryById = params => post('/template/directory/DeleteDirectoryById', params)
export const GetTemplateContent = params => post('/regime/template/GetTemplateContent', params)
